@import '../../../assets/scss/config';

// .inputContainerStyle {
//   display: flex;
//   align-items: center;
//   justify-content: flex-start;
// }

.labelDesktopStyle {
  font-size: 0.85rem;
  font-weight: 400;
  width: 180px;
  word-wrap: break-word;
  box-sizing: border-box;
  flex-shrink: 0;
}

.textFieldRootStyle {
  // fieldset {
  //   border-radius: $border-radius - 6 !important;
  // }
}

@media (max-width: 500px) {
  .labelDesktopStyle {
    display: none;
  }
}

@media (min-width: 576px) {
  .inputContainerStyle fieldset legend {
    // display: none;
    width: 0 !important;
  }
}

.inputContainerStyle input {
  border-color: yellow;
}
