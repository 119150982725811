@import '../../../../../../assets/scss/config';

.root {
  width: 100%;
  padding-bottom: 20px !important;
}

.Collapse {
  background-color: $color-back-submenu;
  box-shadow: inset 0px 5px 9px -10px rgba(0, 0, 0, 0.2);
}

.subitem {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.textButton {
  align-self: flex-end !important;
}

.icon {
  padding-left: 0px;
  padding: 4px;
  align-items: center;
  min-width: 0px !important;
  margin-right: 15px;
  border-radius: 6px;
  // min-height: 30px;
  justify-content: center;

  @media (min-width: 600px) {
    // padding-left: 5px;
  }
}

.active {
  // color: $color-primary !important;
  font-weight: 500;
}

// .active > div > span {
// 	color: $color-primary !important;
// }

.active .icon {
  // color: $color-primary;
}
