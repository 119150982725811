$color-primary: #ff6633;
$color-secondary: #00bea5;
$color-background: #ffffff;
$color-tertiary: #cc8914;
$color-links: #546e7a;
$color-table-headers: #f6f7fc;
$color-select-multiple: #9eb3c5;
$color-back-submenu: #f8f8f8;
$color-danger: #dc3545;
$color-warning: #ffc107;
$color-text-contrast: #f4f6f8;
$color-button-selected: #f0e6de;
$color-top-bar: #ffffff;
$color-report-button: #ffffff;
$color-text-report-button: #212529;
$color-label-chip: #e8f2fd;
$color-label-chip-text: #4a98ee;
$color-tabs: #ffffff;
$color-not-focus-tabs: rgba(238, 238, 238, 1);

$font-size: 100%;
$font-stack--main: 'Montserrat', Helvetica, sans-serif;
$border-radius: 14px;
$color-fonts-primary: #575b6b;

@mixin textParagragh($fontSize: 1rem) {
  text-align: justify;
  font-size: $fontSize;
  line-height: 1.3rem;
}
