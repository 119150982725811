.root {
	padding: 10px 10px;
}

.stages {
	height: 100%;
}

.report {
	// height: 60%;
	width: 100%;
	height: 100%;
	// margin: 15px 0 15px 13px;
}

.kpiContainer {
	width: 19%;
}

.rootButton {
	display: flex;
	flex-direction: row;
	justify-content: center;
	position: relative;
}

.pay {
	display: flex;
	align-items: center;
	width: 20%;
}

.buttonProgress {
	position: absolute;
	top: 17px;
	left: 50%;
	margin-top: -12px;
	margin-left: -12px;
}

@media (min-width: 768px) {
}
