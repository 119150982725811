// .buttonIconAction {
//   float: left;
// }

.buttonContainer{
  background-color: white;
  padding: 3px 4px;
  border-radius: 4px;
  color: #ffffff;
}

.buttonDanger{
  background-color: #f6727a;
}

.buttonPrimary{
  background-color: #1e80eb;
}
