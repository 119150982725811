@import './config';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: $font-size; /* 62.5% of 16px = 10px */
}

body {
  // background-color: #f4f6f8;
  height: 100%;
  font-family: $font-stack--main;
  // font-size: 16px !important;
}

a {
  text-decoration: none;
}

#root {
  height: 100%;
}

#roottest {
  height: 100%;
  padding: 20px;
}

.MainCardRoot {
  box-shadow: none !important;
}

// .MuiButton-root.MuiButton-contained.roundedButton {
//   // border-radius: 20px;
// }

// .MuiButton-root.MuiButton-contained.bigButton {
//   padding: 12px 22px;
//   // border-radius: 25px;
// }

.MuiCardContent-root .kJdJTV {
  min-height: 670px;
}

.table tr:first-child td,
.table tr:first-child th {
  border-top: none;
}

@media (max-width: 600px) {
  .table td,
  .table th {
    padding: 0.75rem;
    vertical-align: top;
    // border-top: none;
  }
}

.MuiGrid-root .ico-recomendation {
  width: 35px;
}

@media (max-width: 1500px) {
  .MuiGrid-root .ico-recomendation {
    width: 25px;
  }
}

@media (max-width: 1389px) {
  .MuiGrid-root .ico-recomendation {
    width: 20px;
  }
}

@media (min-width: 600px) and (max-width: 1375px) {
  .MuiGrid-root .img-estimated,
  .MuiFormLabel-root .img-estimated {
    display: none;
  }
}

@media (max-width: 600px) {
  .MuiGrid-root .img-estimated {
    margin-right: 10px;
  }
}

@media (min-width: 600px) {
  .MuiFormLabel-root img.img-estimated {
    margin-right: 10px;
  }
}

@media (min-width: 600px) and (max-width: 1375px) {
  .padding-col {
    padding-left: 5px;
    padding-right: 5px;
  }
}

// para columnas menos separadas, gutter de 10
.row.gutter_10 {
  margin-right: -10px;
  margin-left: -10px;
}
.gutter_10 > [class^='col-'],
.gutter_10 > [class^=' col-'] {
  padding-right: 10px;
  padding-left: 10px;
}

.highcharts-axis-labels span {
  word-break: break-all !important;
  width: 75px !important;
  white-space: normal !important;
}

div[role='presentation'].MuiDialog-root.MuiModal-root {
  .MuiStack-root {
    gap: 1rem;
  }

  h2:not(.delete-prompt) {
    font-size: 22px !important;
    border-bottom: 2px solid #195499;
  }
  label.MuiInputLabel-shrink.MuiFormLabel-colorPrimary.MuiInputLabel-standard {
    color: #1a2831 !important;
    font-size: 21px !important;
  }
  label.MuiInputLabel-shrink.MuiFormLabel-colorPrimary.MuiInputLabel-standard.Mui-disabled {
    color: #1a2831 !important;
    font-size: 14px !important;
  }
  input.Mui-disabled {
    -webkit-text-fill-color: #1a2831 !important;
    color: #1a2831 !important;
    font-size: 13px !important;
    padding: 1px 0 1px !important;
  }

  .MuiInputBase-fullWidth.MuiInputBase-formControl {
    font-size: 22px;
  }

  .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-colorPrimary.Mui-disabled:before {
    border-bottom: 1px solid rgba(0, 0, 0, 0.06) !important;
  }
}

// div.css-zw3mfo-MuiModal-root-MuiDialog-root
//   .css-kmbp4y-MuiInputBase-root-MuiInput-root.Mui-disabled:before {
//   border-bottom: 1px solid rgba(0, 0, 0, 0.06) !important;
// }

// tabla react table pop over

.MuiPaper-root.MuiMenu-paper.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper {
  ul.MuiList-root {
    li.css-gb5nai-MuiButtonBase-root-MuiMenuItem-root,
    li.css-ws41na-MuiButtonBase-root-MuiMenuItem-root,
    li.css-2d6ig3-MuiButtonBase-root-MuiMenuItem-root {
      padding-top: 0px;
      padding-bottom: 0px;
      // background-color: tomato !important;
      div.css-1pc03ml {
        display: flex;
        align-items: center;
        button.css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root {
          svg {
            font-size: 20px;
          }
        }
        label.css-j204z7-MuiFormControlLabel-root {
          margin-bottom: 0px;
          span.css-1gukwju-MuiTypography-root {
            font-size: 14px;
          }
        }
      }
    }
  }
}

div:not(#profile-menu)
  .MuiPaper-root.MuiMenu-paper.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper {
  ul.MuiList-root.MuiMenu-list {
    li.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root.MuiMenuItem-gutters {
      padding-top: 0px;
      padding-bottom: 0px;
      // background-color: tomato !important;
      div.css-1pc03ml {
        display: flex;
        align-items: center;
        button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeSmall {
          svg {
            font-size: 20px;
          }
        }
        label.MuiFormControlLabel-root {
          margin-bottom: 0px;
          span.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label {
            font-size: 14px;
          }
        }
      }
    }
  }
}
