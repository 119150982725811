@import '../../assets/scss/config';

.root {
	background-color: $color-background;
	height: 100%;
	background-image: url('../../assets/bg-auth.png');
	background-size: cover;
}

// @media (max-width: 600px) {
// 	.root {
// 		background: none;
// 		background-color: #ffffff;
// 	}
// }

.name {
	color: #ffffff !important;
	margin-top: 8px !important;
	text-align: center;
	text-shadow: 0px 0px 5px rgba(35, 65, 91, 0.9);
}

.bio {
	color: #ffffff !important;
}

.content {
	min-height: 55vh;
	display: flex;
	flex-direction: column;
	background-color: rgba(255, 255, 255, 1);
	border-radius: 10px;
	-webkit-box-shadow: 0px 0px 24px 8px rgba(0, 0, 0, 0.2);
	box-shadow: 0px 0px 24px 8px rgba(0, 0, 0, 0.2);
	// backdrop-filter: blur(3px);

	// @media (max-width: 600px) {
	// 	background-color: rgba(255, 255, 255, 1);
	// 	border-radius: 0;
	// 	min-height: 100vh;
	// 	border: none;
	// }
}

.contentBody {
	// display: flex;
	// flex-grow: 1;
	padding: 40px 50px;
	// align-items: center;
	@media (max-width: 600px) {
		padding: 18px;
		margin-top: 20px;
	}
}

@media (max-width: 1279px) {
	.contentBody {
		justify-content: center;
	}
}

.contentHeader {
	display: flex;
	align-items: center;
	padding-top: 40px;
	padding-left: 16px;
	padding-right: 16px;
	padding-bottom: 16px;
}

.title {
	margin-top: 24px !important;
}

.socialButtons {
	margin-top: 24px !important;
}

.socialIcon {
	margin-right: 8px !important;
}

.sugestion {
	// margin-top: 16px !important;
}

.form {
	// flex-basis: auto;
	// padding-left: 100px;
	// padding-right: 100px;
	// padding-bottom: 125px;
}

// @media (max-width: 959px) {
// 	.form {
// 		padding-left: 16px;
// 		padding-right: 16px;
// 	}
// }

// .textField {
// 	margin-top: 16px !important;
// }

.wrapper {
	margin: 24px 0;
	position: relative;
}

.signInButton {
	margin: 16px 0px !important;
	border-radius: $border-radius !important;
}

.progress {
	position: absolute;
	top: 50%;
	color: white !important;
	left: 50%;
	z-index: 10;
	margin-top: -12px;
	margin-left: -12px;
}

@media (max-width: 600px) {
	.imgPhone {
		max-width: 180px;
	}
}
